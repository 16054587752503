<!--
 * @Date: 2024-08-02 19:16:08
 * @LastEditors: Peng_Yong
 * @LastEditTime: 2025-01-25 23:18:06
-->
<script setup lang="ts">
import Quill from 'quill';
import { defineProps } from 'vue';

/*
 *variable
 */
const props = defineProps({
    id: {
        type: String,
        default: 'editor'
    },
    modelValue: {
        type: String,
        default: ''
    }
});

let quill: Quill;

const editor = ref();
const emit = defineEmits(['update:modelValue']);

const register = (Quill: any) => {
    // 引入源码中的BlockEmbed
    const BlockEmbed = Quill.import('blots/block/embed');
    // 定义新的blot类型
    class AppPanelEmbed extends BlockEmbed {
        static create(value) {
            const node = super.create(value);
            node.setAttribute('contenteditable', 'false');
            node.setAttribute('width', '100%');
            //   设置自定义html
            node.innerHTML = this.transformValue(value);
            return node;
        }

        static transformValue(value) {
            let handleArr = value.split('\n');
            handleArr = handleArr.map(e => e.replace(/^[\s]+/, '').replace(/[\s]+$/, ''));
            return handleArr.join('');
        }

        // 返回节点自身的value值 用于撤销操作
        static value(node) {
            return node.innerHTML;
        }
    }
    // blotName
    AppPanelEmbed.blotName = 'AppPanelEmbed';
    // class名将用于匹配blot名称
    AppPanelEmbed.className = 'embed-innerApp';
    // 标签类型自定义
    AppPanelEmbed.tagName = 'div';
    Quill.register(AppPanelEmbed, true);
};

onMounted(() => {
    quill = new Quill(`#${props.id}`, {
        theme: 'snow',
        modules: {
            toolbar: [
                'bold',
                'italic',
                'underline',
                { header: [1, 2, 3, 4, 5, 6] },
                'blockquote',
                'code-block',
                'code',
                'link',
                { list: 'ordered' },
                { list: 'bullet' },
                'image',
                { size: [] },
                { color: [] },
                { background: [] }
            ],
            keyboard: {
                bindings: {
                    strike: {
                        key: 'S',
                        ctrlKey: true,
                        shiftKey: true,
                        handler: function (range, context) {
                            // 获取当前光标所在文本的格式
                            const format = this.quill.getFormat(range);
                            // 增加/取消删除线
                            this.quill.format('strike', !format.strike);
                        }
                    }
                }
            },

            // 新增的
            history: {
                delay: 2000, // 2s记录一次操作历史
                maxStack: 200 // 最大记录200次操作历史
            }
        }
    });
    register(Quill);
    quill.on('text-change', () => {
        emit('update:modelValue', quill.root.innerHTML);
    });
    quill.insertEmbed(quill.getSelection()?.index || 0, 'AppPanelEmbed', props.modelValue);
});
</script>

<template>
    <div :id="props.id" ref="editor"></div>
</template>

<style>
@import 'quill/dist/quill.snow.css';
</style>
