/*
 * @description: 主入口文件
 * @Author: James324
 * @Date: 2023-08-21 13:59:03
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-09-28 00:11:10
 */
import { createApp } from 'vue';
import piniaPersist from 'pinia-plugin-persistedstate';
import { createPinia } from 'pinia';
import directives from './directives';
import App from './App.vue';
import baseComponent from './components/index';
import '@/assets/style';
import 'element-plus/theme-chalk/dark/css-vars.css';
import { setRootSize } from '@/utils/common';
import 'animate.css';
import router from './router';
import * as buffer from 'buffer';
import 'element-plus/es/components/message/style/css.mjs';
import 'element-plus/es/components/message-box/style/css.mjs';
if (typeof (window as any).global === 'undefined') {
    (window as any).global = window;
}
if (typeof (window as any).Buffer === 'undefined') {
    (window as any).Buffer = buffer.Buffer;
}

setRootSize();
const pinia = createPinia();
pinia.use(piniaPersist); // 数据持久化
const app = createApp(App);
function initApp() {
    app.use(pinia);
    app.use(directives);
    app.use(router);
    app.use(baseComponent);
    app.mount('#app');
}

setTimeout(() => {
    initApp();
}, 600);
